<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <b-container>
    <b-row>
      <b-col
        class="d-flex justify-content-center align-items-center"
        sm="12"
        md="6"
      >
        <b-img :src="require('@/assets/images/pages/coming-soon.svg')" />
      </b-col>
      <b-col
        sm="12"
        md="6"
      >
        <b-card>
          <b-card-title>
            Crear nuevo usuario
          </b-card-title>
          <validation-observer ref="formValidation">
            <b-form @submit.prevent="validateForm()">
              <b-form-group label="Nombre">
                <validation-provider
                  #default="{ errors }"
                  name="Nombre"
                  rules="required"
                >
                  <b-form-input v-model="user.name" autocomplete="off" type="text" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input v-model="user.email" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-button type="submit">
                  {{ $t('add', { type: '' }) }}
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      loading: false,
      user: {
        name: '',
        email: '',
      },
    }
  },
  methods: {
    ...mapActions('auth', ['createCustomer']),
    validateForm() {
      this.$refs.formValidation.validate().then(success => {
        if (success) {
          this.loading = true
          this.createCustomer(this.user)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('customerCreated'),
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            })
            .then(() => {
              this.$router.push({ name: 'customers' })
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
